import React, { useState, useEffect } from "react"
import NewsPage from "../components/news-page"
import * as haikus from "../model/haikus"
import Layout from "../components/layout"
import "./subscribe.css"

const IndexPage = (foo) => {


  return (
    <Layout>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 420,
          // padding: `1.45rem 1.0875rem`,
          display: "flex"
        }}
      >
        <div id="mc_embed_signup">
          <form
            action="https://newshaikus.us19.list-manage.com/subscribe/post?u=3bf1c133214ef668eb602a3ee&amp;id=0d456e7f3e"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
            target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">

              {/*<div id="mce-responses" class="clear">*/}
              {/*  <div class="response" id="mce-error-response" style="display:none"></div>*/}
              {/*  <div class="response" id="mce-success-response" style="display:none"></div>*/}
              {/*</div>*/}

              {/*<div  aria-hidden="true">*/}
              {/*  <input type="text"*/}
              {/*                                                                          name="b_3bf1c133214ef668eb602a3ee_0d456e7f3e"*/}
              {/*                                                                          tabindex="-1" value=""/>*/}
              {/*</div>*/}
              <br/>
              <h4 className="hero">
                Subscribe for the news,<br/>
                Sent to your inbox, weekly,<br/>
                As silly haikus.<br/>
              </h4>


              <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="Email Address"/>
              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"
                     className="button"/>
            </div>
          </form>
        </div>
      </div>

    </Layout>

  )}

export default IndexPage
